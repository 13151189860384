import { MessageModel } from '@/model/api/Message';
import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { Shift } from '@/model/api/Shift';
import { plainToClass } from 'class-transformer';
import { CRUD }  from './base/crud';

class ShiftsService extends CRUD<Shift, any> {
    readonly endPoint = `shifts`;

    public async index(params?: any): Promise<PaginatedResponse<Shift>> {
        const response = await super.index(params);

        response.data = plainToClass(Shift, response.data);

        return response;
    }

    public async getById(id: number): Promise<Shift> {
        const response = await super.getById(id);
        return plainToClass(Shift, response);
    }

    public exportExcel(params?: any): Promise<string>{
        // TODO Attendere API 
        // if (filters) {
        //     Object.keys(filters).forEach(k => {
        //         const v = filters[k].value; 
        //         const n = filters[k].nullable; 
    
        //         if (!v && !n) {
        //             delete filters[k];
        //         }
        //     });

        //     if (!Object.keys(filters)?.length) {
        //         filters = null; 
        //     }
        // }

        return this.get<string>(`${this.endPoint}/export`,  {
            responseType: 'arraybuffer',
            params: { }
        })
    }

    public async autocomplete(field: string, q: string) {
        return this.get(`${this.endPoint}/field-autocomplete`, {
         params: {
             field,
             q,
         }
        });
    }

    public async disable(id: Number) {
        return this.post(`${this.endPoint}/${id}/disable` );
    }
}

export const shiftsService = new ShiftsService();



