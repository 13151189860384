import { Shift, ShiftStatusEnum } from "@/model/api/Shift";
import { ShiftsRoutesEnum } from "@/modules/shifts/router";
import { shiftsService } from "@services/shifts.service";
import { FilterMatchMode } from "primevue/api";
import { Options, Vue } from "vue-class-component";

import {AppTable, DuplicateShiftDialog, SendMessageDialog} from "@components";
import moment from "moment";
import Table from "@components/AppTable/Table";

@Options({
  components: {
    AppTable,
    DuplicateShiftDialog,
    SendMessageDialog
  }
})
export default class ShiftsTable extends Vue {
  filters: any = null;

  showDuplicateDialog: boolean = false;
  selectedShift: Shift = null;

  get table() {
    return this.$refs.dataTable as Table;
  }

  onClone(shift: Shift) {
    this.selectedShift = shift;

    this.showDuplicateDialog = true;
  }

  onDuplicateCancel() {
    this.showDuplicateDialog = false;
  }

  displayMessageDialog: boolean = false;
  msgShiftId: number = null;

  private sendMessageFor(shiftId: number) {
    this.msgShiftId = shiftId;
    this.displayMessageDialog = true;
  }

  async onDuplicateConfirm(clone: Shift) {
    try {
      const cloned = await shiftsService.create(clone);

      // this.sendMessageFor(cloned.id);

      await this.$successMessage(this.$t('shift.duplicated_message'));

      // this.table.applyFilter();

      this.showDuplicateDialog = false;

      await this.$router.push({
        name: ShiftsRoutesEnum.SHIFTS_DETAIL,
        params: { shiftId: cloned.id }
      })
    } catch (error) {
      this.$errorMessage(this.$t('Operation failed'))
    }

  }

  get service() {
    return shiftsService;
  }
  
  getRowClass({ trucks_engaged, trucks_min, trucks_required, status }: Shift) {
    if(status === ShiftStatusEnum.DISABLED) {
      return "shift_disabled";
    }
    
    if (trucks_engaged >= trucks_required) {
      return "truck_required";
    } else if ( 
      trucks_min > 0 &&
      trucks_engaged <= trucks_required && 
      trucks_engaged >= trucks_min 
    ) {
      return "truck_min";
    }

    return null; 
  }

  goToDetail(shift: Shift) {
    this.$router.push({
      name: ShiftsRoutesEnum.SHIFTS_DETAIL,
      params: { shiftId: shift.id }
    })
  }

  private initFilter() {
    this.filters = {
      global: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      shift_day: {
        value: moment().format("YYYY-MM-DD"),
        matchMode: FilterMatchMode.EQUALS
      }
    };
  }

  created() {
    this.initFilter();
  }
}