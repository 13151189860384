import { BaseApiModel, IBaseApiModel } from "../common/BaseApiModel"
import { TicketFinishStatusEnum, TicketStatusEnum } from "../enums/TicketStatusEnum";
import timezone, { Moment } from "moment-timezone";
import { Transform } from "class-transformer";
import { User } from "./User";
import { Shift } from "./Shift";
import {Contract} from "@/model/api/Contract";
import {PaymentTypeEnum} from "@/model/enums/PaymentTypeEnum";
import {PaymentStatusEnum} from "@/model/enums/PaymentStatusEnum";

export class Ticket extends BaseApiModel {
  user_id ?: number;
  user    ?: User;

  shift_id       ?: number;
  shift          ?: Shift;

  free_dump_accepted_at?: Date;
  free_dump_requested_at?: Date;

  contract_id    ?: number;
  contract       ?: Contract;
  status         ?: TicketStatusEnum;
  fee            ?: number;
  cycles         ?: number;
  total_ton      ?: number;
  system_warning ?: number;
  ticket_day     ?: string;
  finish_status  ?: TicketFinishStatusEnum;
  finish_note    ?: string;
  dispute_note   ?: string;
  rate           ?: number;

  payment_status ?: PaymentStatusEnum

  /**
   * TimeZone
   */
  zone_name?: string;

  @Transform(
    ({ value, obj }) => timezone.tz(value, obj.zone_name),
    { toClassOnly: true }
  )
  @Transform(
    ({ value, obj }) => value.tz(obj.zone_name, true)
      .clone().tz("utc")
      .format("YYYY-MM-DDTHH:mm:ss.000000\\Z"),
    { toPlainOnly: true }
  )
  datetime_start?: Moment;

  @Transform(
    ({ value, obj }) => timezone.tz(value, obj.zone_name),
    { toClassOnly: true }
  )
  @Transform(
    ({ value, obj }) => value.tz(obj.zone_name, true)
      .clone().tz("utc")
      .format("YYYY-MM-DDTHH:mm:ss.000000\\Z"),
    { toPlainOnly: true }
  )
  datetime_end?: Moment;

  @Transform(
    ({ value, obj }) => timezone.tz(value, obj.zone_name),
    { toClassOnly: true }
  )
  @Transform(
    ({ value, obj }) => value.tz(obj.zone_name, true)
      .clone().tz("utc")
      .format("YYYY-MM-DDTHH:mm:ss.000000\\Z"),
    { toPlainOnly: true }
  )
  datetime_assigned?: Moment;
}


export interface TicketHistory extends IBaseApiModel{
  ticket_id: number;

  lat: number;
  lng: number;

  note: string;

  ton: number;
  ton_image_path: string;

  status: TicketStatusEnum;
}

export interface CalculatedFee {
  cycles       : number;
  fee          : number;
  payment_type : number;
}