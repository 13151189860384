import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { CalculatedFee, Ticket, TicketHistory } from '@/model/api/Ticket';
import { TicketStatusEnum } from '@/model/enums/TicketStatusEnum';
import { classToPlain, plainToClass } from 'class-transformer';
import { Moment } from 'moment';
import { CRUD } from './base/crud';

class TicketsService extends CRUD<Ticket, any> {
    readonly endPoint = `tickets`;

    public async index(params?: any): Promise<PaginatedResponse<Ticket>> {
        const response = await super.index(params);

        response.data = plainToClass(Ticket, response.data);

        return response;
    }

    public async getById(id: number): Promise<Ticket> {
        const response = await super.getById(id);

        return plainToClass(Ticket, response);
    }

    public deleteBySystem(item: Ticket): Promise<Ticket> {
        return this.delete<Ticket>(
            `${this.endPoint}/by_system/${item.id}`
        );
    }

    public deleteStandard(item: Ticket): Promise<Ticket> {
        return this.delete<Ticket>(
            `${this.endPoint}/${item.id}`
        );
    }

    public history(id: number): Promise<TicketHistory[]> {
        return this.get<TicketHistory[]>(`${this.endPoint}/${id}/history`)
            .then(response =>
                response.sort((a, b) =>
                    new Date(a.created_at).valueOf()
                    - new Date(b.created_at).valueOf()
                )
            );
    }

    public setStatus(
        id: number, 
        status: TicketStatusEnum,
        ticket: Ticket
    ) {
        const plainTicket: Ticket = classToPlain(ticket);

        return this.post(`${this.endPoint}/${id}/set-status`, 
            {
                status,
                datetime_start : plainTicket.datetime_start,
                datetime_end   : plainTicket.datetime_end,
                cycles         : plainTicket.cycles,
                finish_note    : plainTicket.finish_note
            }
        );
    }

    public patchFee(id: number, fee: number) {
        return this.patch(`${this.endPoint}/${id}`, {fee});
    }

    public calcFee(ticket: Ticket): Promise<CalculatedFee> {
        const plainTicket: Ticket = classToPlain(ticket);

        return this.post(`${this.endPoint}/${ticket.id}/calculate-fee`, 
            {
                datetime_start : plainTicket.datetime_start,
                datetime_end   : plainTicket.datetime_end,
                contract_id    : plainTicket.contract_id,
                total_ton      : plainTicket.total_ton || null,
                cycles         : plainTicket.cycles
            }
        );
    }

    public exportFile(filters?: any, type?: string): Promise<string>{
        if (filters) {
            Object.keys(filters).forEach(k => {
                const v = filters[k].value; 
                const n = filters[k].nullable; 

                if (!v && !n) {
                    delete filters[k];
                }
            });

            if (!Object.keys(filters)?.length) {
                filters = null; 
            }
        }

        if(type === 'invoice'){
            return this.get<string>(`${this.endPoint}/export-invoice`,  {
                responseType: 'arraybuffer',
                params: { filters, type: 'excel' }
            })
        }
        return this.get<string>(`${this.endPoint}/export`,  {
            responseType: 'arraybuffer',
            params: { filters, type }
        })
    }
}

export const ticketsService = new TicketsService();



