
import { defineComponent } from "vue";
import {materialsService} from "@services/materials.service";

export default defineComponent( {
  name: "MaterialSelect",
  props: ["modelValue", "showClear"],
  emits: ['update:modelValue', 'selected'],
  data() {
    return {
      materials: []
    }
  },
  computed: {
    selectedMaterial: {
      get() {
        return parseInt(this.modelValue);
      },
      set(val) {
        this.$emit('update:modelValue', val);
        this.$emit('selected', val);
      }
    }
  },
  async mounted() {
    this.materials = await materialsService.getAll({ sortField: 'name', sortOrder: '1' })
  }
} )
