import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "p-mb-3"
}
const _hoisted_3 = { class: "p-d-flex p-jc-around" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageEditor = _resolveComponent("MessageEditor")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_PDialog = _resolveComponent("PDialog")!

  return (_openBlock(), _createBlock(_component_PDialog, _mergeProps({
    modal: true,
    class: "p-fluid",
    draggable: true,
    keepInViewPort: true,
    minX: 0,
    minY: 0,
    header: "Send message",
    onShow: _ctx.onShow
  }, _ctx.$attrs), {
    footer: _withCtx(() => [
      (_ctx.requestPending)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ProgressBar, {
              mode: "indeterminate",
              style: {"height":".5em"}
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Button, {
          label: "Cancel",
          class: "p-button-info",
          icon: "pi pi-times",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:visible', false))),
          disabled: _ctx.requestPending
        }, null, 8, ["disabled"]),
        _createVNode(_component_Button, {
          label: "Send",
          icon: "pi pi-send",
          disabled: _ctx.requestPending,
          onClick: _ctx.send
        }, null, 8, ["disabled", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      (_ctx.hint)
        ? (_openBlock(), _createElementBlock("h6", _hoisted_1, _toDisplayString(_ctx.hint), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_MessageEditor, {
        message: _ctx.message,
        "onUpdate:message": _cache[0] || (_cache[0] = ($event: any) => (_ctx.message = $event)),
        disabled: _ctx.requestPending
      }, null, 8, ["message", "disabled"])
    ]),
    _: 1
  }, 16, ["onShow"]))
}