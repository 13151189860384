
  import { ref, defineComponent } from "vue";
  import { SendMessageDialog } from "@components";

  export default defineComponent({
    props: ["ticket"],

    components: {
      SendMessageDialog,
    },

    setup() {
      const displayMessageDialog = ref(false);

      return {
        displayMessageDialog,
      };
    },
  });
