import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_DynamicTable = _resolveComponent("DynamicTable")!
  const _component_PDialog = _resolveComponent("PDialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_PDialog, _mergeProps({
    modal: true,
    class: "p-fluid",
    keepInViewPort: true,
    onShow: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onShowDialog())),
    minX: 0,
    minY: 0,
    header: _ctx.$t('shift.add_user_extended')
  }, _ctx.$attrs, { style: {width: '1280px'} }), {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Cancel",
        class: "p-button-info",
        icon: "pi pi-times",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:visible', false)))
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_DynamicTable, {
        ref: "table",
        cols: _ctx.cols,
        filtersSchema: _ctx.filters,
        service: _ctx.service,
        onRowClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onShow($event.data))),
        onEdit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onShow($event)))
      }, {
        actions: _withCtx(({data}) => [
          (_ctx.isWhitelisted(data))
            ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                key: 0,
                style: {"flex":"1"},
                class: "p-button-danger",
                icon: "pi pi-trash",
                onClick: _withModifiers(($event: any) => (_ctx.onRemove(data)), ["stop"])
              }, null, 8, ["onClick"])), [
                [
                  _directive_tooltip,
                  'Remove from whitelist',
                  void 0,
                  { bottom: true }
                ]
              ])
            : _createCommentVNode("", true),
          (!_ctx.isWhitelisted(data))
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                style: {"flex":"1"},
                class: "p-button-success",
                label: "Add",
                onClick: _withModifiers(($event: any) => (_ctx.onAdd(data)), ["stop"])
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["cols", "filtersSchema", "service"])
    ]),
    _: 1
  }, 16, ["header"]))
}