import { Options, Vue }     from "vue-class-component";
import { Prop }             from "vue-property-decorator";

import moment               from "moment";
import timezone             from "moment-timezone";

import { ticketsService }   from "@services/tickets.service";
import { TicketStatusEnum } from "@/model/enums/TicketStatusEnum";
import { TicketHistory }    from "@/model/api/Ticket";

import TableSkeleton        from "@/components/TableSkeleton/TableSkeleton.vue";
import TicketStatus         from "@/components/TicketStatus/TicketStatus.vue";
import {TonImageDialog} from "@components";

@Options({
  name: 'TicketHistoryComponent',
  components: {
    TableSkeleton,
    TicketStatus,
    TonImageDialog
  }
})
export default class TicketHistoryComponent extends Vue {
  @Prop() readonly ticketId!: number;

  @Prop() readonly timeZone!: string;

  @Prop() readonly hiddenColumns!: any;

  displayTonDialog  : boolean = false;
  tonDialogSrc      : string   = '';

  history: TicketHistory[] = null;

  get TicketStatusEnum() {
    return TicketStatusEnum;
  }

  timeZoneDate(date: string) {
    return timezone
      .tz(date, this.timeZone)
      .format("hh:mm A")
  }

  private _loadhistory() {
    this.$waitFor(async () => {
      this.history = await ticketsService.history(this.ticketId);
    })
  }

  showImage(ton_image_path_url: string){
    this.tonDialogSrc = ton_image_path_url
    this.displayTonDialog = true
  }

  getDurationFrom(idx: number, th: TicketHistory){
    if ( this.history.length < idx+2) return; 

    const startMoment = moment(th.created_at)
    const endMoment   = moment(this.history[idx+1].created_at);

    const d = moment.duration(endMoment.diff(startMoment));

    const h = Math.floor(d.asHours());
    const m = Math.floor(d.minutes());
    const s = Math.floor(d.seconds());

    return [h,m,s].map(x => x.toString().padStart(2, '0')).join(':');
  }

  timeDiffNext(idx: number, th: TicketHistory){
    const format = "hh:mm A";

    let result = timezone.tz(th.created_at, this.timeZone).format(format);

    if (this.history.length > idx+1){
      const endMoment   = timezone.tz(
        this.history[idx+1].created_at, 
        this.timeZone
      )?.format(format);

      result += ` - ${endMoment}`;
    }

    return result;
  }
  

  created() {
    if (this.ticketId) {
      this._loadhistory();
    }
  }
}