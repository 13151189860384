import { PrimeVueEvent } from "@/model/ui/PrimeVueTypes";
import { AuthRoutesEnum } from "@/modules/auth/router";
import { localStorageSVC } from "@plugins/LocalStorage-plugin";
import { CRUD }          from "@services/base/crud";
import { Options }           from "vue-class-component";
import { Prop, Vue }          from "vue-property-decorator";

@Options({
    name: "DynamicTable",
})
export default class TableMixin extends Vue {
    @Prop()
    cols: any[] = null;

    @Prop()
    service: CRUD<any, any> = null;

    @Prop()
    readonly filtersSchema : any;

    @Prop()
    readonly stateKey : string;

    @Prop()
    readonly deletMsgFn : (data) => string;

    @Prop({ default: 'Deleting successfully completed' })
    readonly deleteSuccessMsg : string;

    @Prop({ default: 'Deleting failed' })
    readonly deleteErrorMsg : string;

    @Prop({ default: true })
    readonly showActions : boolean;

    filters: any = null;

    firstLoading: boolean = true;

    private loading: boolean = false;

    set isLoading(value: boolean) {
        this.loading = value;
    }
    get isLoading() {
        return this.loading;
    }

    dataset: any[] = null;

    totalRecords = 0;

    get showGlobalFilter() {
        return this.filters?.global;
    }

    get dt(): any {
        return this.$refs.dt;
    }

    public applyFilter() {
        this.loadData(this.getFilters());
    }

    private initFilter() {
        if (this.filtersSchema) {
            this.filters = JSON.parse(JSON.stringify(this.filtersSchema));
        }
    }

    private async loadData(params?: PrimeVueEvent) {
        params?.originalEvent && delete params.originalEvent;

        try {
            this.isLoading = true;

            const {
                filters,
                sortField,
                sortOrder
            } = params;

            const p = {
                sortField,
                sortOrder,
                per_page : params?.rows,
                page     : (params?.first ? params.first / params.rows : 0) + 1,
            } as any;

            if (filters && Object.keys(filters).length) {
                p.filters = {...filters};
            }

            const response = await this.service.index(p);

            const { data, total } = response;

            this.dataset      = data;
            this.totalRecords = total;

        } catch (error: any) {
            console.error(error);

            if (error.status === 401) {
                localStorageSVC.clearToken(); 
                this.$router.replace({name: AuthRoutesEnum.SIGN_IN});
            }

            this.$errorMessage("", "Dataset loading aborted");

        } finally {
            this.isLoading      = false;
            this.firstLoading   = false;
        }
    }

    private getFilters() {
        return {
            ...this.dt.createLazyLoadEvent(),
            filters : this.filters
        }
    }

    onApplyFilter(filterCallback?: Function) {
        filterCallback && filterCallback();
        this.applyFilter();
    }

    onClearFilter() {
        this.initFilter();
        this.$emit('resetFilters');
        this.loadData(this.getFilters());
    }

    refresh() {
        this.loadData(this.getFilters());
    }

    onGlobalFilterSubmit() {
        this.loadData(this.getFilters());
    }

    onPage(e: any): any {
        const pe = e as PrimeVueEvent;

        this.loadData(pe);
    }

    onSort(e: Event): any {
        const pe = e as PrimeVueEvent;
        this.loadData(pe);
    }

    onDelete(data: any) {
        let message = "Are you sure to proceed with cancellation?"
        if (this.deletMsgFn) {
            message = this.deletMsgFn(data);
        }

        this.$confirmMessage(message)
            .then(async response => {
                if (response) {
                    this.$waitFor(
                        async () => {
                            await this.service.deleteBySystem(data);
                            this.loadData(this.getFilters());
                            this.$successMessage(this.deleteSuccessMsg);
                        },
                        this.deleteErrorMsg
                    )
                }
            })

    }

    created() {
        this.initFilter();
    }

    mounted() {
        this.loadData(this.getFilters());
    }
}