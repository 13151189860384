
import { defineComponent } from "vue";
import { UserRoleEnum }  from "@/model/enums/UserRoleEnum";

export default defineComponent( {
  name: "UserRoleSelect",
  props: ["modelValue", "showAdmin"],
  data() {
    return {
      commonOptions: [
        {
          value: UserRoleEnum.OWNER_OPERATOR,
          label: 'Owner Operator'
        },
        {
          value: UserRoleEnum.USER,
          label: 'User'
        }
      ]
    }
  },
  computed: {
    selectVal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    },
    options() {
      if(this.showAdmin){
        return this.commonOptions.concat({
          value: UserRoleEnum.SUPER_ADMIN,
          label: 'Admin'
        })
      }
      return this.commonOptions
    },
  }

} )
