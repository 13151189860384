import { CRUD }  from './base/crud';
import {User} from "@/model/api/User";
import {plainToClass} from "class-transformer";

class WhitelistUsersService extends CRUD<User, any> {
    readonly endPoint = `shift-whitelist-users`;

    whitelistedUsers: User[] = []

    public async removeUser(item: any): Promise<User> {
        if(item.shift_id){
            await this.delete<User>(`${this.endPoint}`, item);
        }
        this.whitelistedUsers = this.whitelistedUsers.filter((wUser) => wUser.id !== item.user_id)
        return
    }

    public async addUser({shift_id, user}): Promise<User>{
        let plainUser
        if(shift_id){
            plainUser = await this.post<User>(`${this.endPoint}`, {
                shift_id,
                user_id: user.id
            });
        } else {
            plainUser = plainToClass(User, user)
        }
        if(!this.whitelistedUsers.find((user) => user.id === plainUser.id)){
            this.whitelistedUsers.push(plainUser)
        }
        return plainUser
    }

    public clearUsers() {
        this.whitelistedUsers = []
    }

    public async getUsers(params?: any): Promise<User[]> {
       if(!this.whitelistedUsers.length && params){
            const users = await this.get<User[]>(`${this.endPoint}?shift_id=${ params.shift_id }`);
            this.whitelistedUsers = plainToClass(User, users)
        }
        return this.whitelistedUsers;
    }

}

export const whitelistUsersService = new WhitelistUsersService();



