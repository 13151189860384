
  import { defineComponent, toRefs, watch } from "vue";
  import { useAutocompleteWithParams }      from "@/use/useAutocomplete";
  import { zonesService }                   from "@services/zones.service";
  import { Zone }                           from "@/model/api/Zone";
  import { FilterMatchMode }                from "primevue/api";

  export default defineComponent({
    name:'ZoneAutocomplete',
    
    inheritAttrs: false,

    emits: ['select', 'clear'],

    props: [
      "modelValue",
      "zoneType",
      "dropdown"
    ],


    setup(_, { emit }) {
      const { modelValue } = toRefs(_);

      const { 
        onSelect,
        onClear,
        search,
        searchBox,
        suggestions
      } = useAutocompleteWithParams<Zone>({
        service: zonesService,

        params: {
          zone_type: {
            value: _.zoneType, matchMode: FilterMatchMode.EQUALS 
          }
        },

        afterSelect: (zone: Zone) => {
          emit("select", zone);
        },

        afterClear: () => {
          emit("clear");
        }
      });

      watch(
        modelValue,
        () => {
          searchBox.value = modelValue.value;
        },
        {
          immediate: true
        }
      );

      return {
        searchBox,
        suggestions,
        onSelect,
        onClear,
        search,
      };
    },
  });
