import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageEditor = _resolveComponent("MessageEditor")!
  const _component_Button = _resolveComponent("Button")!
  const _component_PDialog = _resolveComponent("PDialog")!

  return (_openBlock(), _createBlock(_component_PDialog, _mergeProps({
    modal: true,
    class: "p-fluid",
    draggable: true,
    keepInViewPort: true,
    minX: 0,
    minY: 0,
    header: "Delete Shift"
  }, _ctx.$attrs, { style: {width: '265px'} }), {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Cancel",
        class: "p-button-info",
        icon: "pi pi-times",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:visible', false)))
      }),
      _createVNode(_component_Button, {
        label: "Delete",
        icon: "pi pi-send",
        onClick: _ctx.onDelete
      }, null, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_MessageEditor, {
        message: _ctx.message,
        "onUpdate:message": _cache[0] || (_cache[0] = ($event: any) => (_ctx.message = $event)),
        messageLabel: "Send message to all users in this shift"
      }, null, 8, ["message"])
    ]),
    _: 1
  }, 16))
}