import { Shift, ShiftStatusEnum } from "@/model/api/Shift";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import moment, {Moment} from "moment";

@Options({})
export default class DuplicateShiftDialog extends Vue {
  @Prop() shift!: Shift;
  newCode: string = null
  clonedShift: Shift = null;

  get today() {
    return moment();
  }

  private _momentToDate(v: Moment) {
    if (!v.isValid()) return null;

    return new Date(
        v.format("YYYY-MM-DDTHH:mm:ss.00")
    );
  }

  onShow() {
    if(this.shift){
      this.newCode = this.shift.code
    }
    this.clonedShift = this.shift;
    let shiftDay: Date
    if(this.clonedShift.start_datetime.isSameOrAfter(this.today)){
      shiftDay = this._momentToDate(this.today.add(1, 'days'))
    } else {
      shiftDay = this._momentToDate(this.today)
    }
    this.clonedShift.shiftDay = shiftDay
    if(this.clonedShift.is_expire_active){
      const mDay = moment(this.clonedShift.shiftDay).tz(this.clonedShift.zone_name, true);
      const [h, m] = this.clonedShift.expireAt.split(':');
      mDay.hours(+h);
      mDay.minutes(+m);
      mDay.seconds(0);

      this.clonedShift.expire_at = mDay || null;
    }
  }

  // get dateFormat() {
  //   return this.$config.dateFormat;
  // }

  confirm() {
    this.$emit('confirm', {
      ...this.shift,
      id: null,
      code: this.newCode,
      trucks_engaged : 0,
      status: ShiftStatusEnum.ENABLED,
    });
  } 
}