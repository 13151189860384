import { Contract } from '@/model/api/Contract';
import { CRUD } from './base/crud';

class ContractsService extends CRUD<Contract, any> {
    readonly endPoint = `contracts`;
}

export const contractsService = new ContractsService();



