import { Options, Vue } from "vue-class-component";

import MessageEditor from "@/components/MessageEditor/MessageEditor.vue";
import {User} from "@/model/api/User";
import {usersService} from "@services/users.service";
import {DynamicTable, UserRole, UserRoleSelect, UserStatus, UserStatusSelect} from "@components";
import {UsersRoutesEnum} from "@/modules/users/router";
import {FilterMatchMode} from "primevue/api";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {whitelistUsersService} from "@services/whitelist_users.service";
import Table from "@components/AppTable/Table";
import {Prop, Watch} from "vue-property-decorator";

@Options({
  emits: ['addUser', 'removeUser'],
  components: {
    MessageEditor,
    DynamicTable
  }
})

export default class WhitelistUserDialog extends Vue {

  filters: any = null;

  private cachedList: User[] = [];

  get service() {
    return usersService;
  }

  get cols(){
    return [
      {
        field       : 'name',
        header      : this.$t('user.table.first_name'),
        placeHolder : "Name contains"
      },
      {
        field       : 'surname',
        header      : this.$t('user.table.last_name'),
        placeHolder : "Surname contains"
      },
      {
        field       : 'email',
        header      : this.$t('user.table.email'),
        placeHolder : "E-mail contains"
      },
      {
        field       : 'role',
        header      : this.$t('user.table.role'),
        filter_component: UserRoleSelect,
        component   : UserRole,
        placeHolder : "Role contains"
      },
      {
        field       : 'phone',
        header      : this.$t('user.table.phone'),
        placeHolder : "Phone contains"
      },
      {
        field       : 'company.name',
        header      : "Supplier company",
        placeHolder : "Supplier company contains"
      },
      {
        field  : 'status',
        header : this.$t('user.status'),
        filter_component: UserStatusSelect,
        component: UserStatus,
        placeHolder : "Status",
      },
    ] as {
      field?: keyof User,
      [key:string]:any
    }[]
  }

  goToDetail(user: User) {
    this.$router.push({
      name: UsersRoutesEnum.USER_DETAIL,
      params: { userId: user.id }
    })
  }

  private initFilter() {
    this.filters = {
      global: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      name: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH,
      },
      surname: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH,
      },
      email: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      phone: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      status: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      role: {
        value: UserRoleEnum.USER,
        matchMode: FilterMatchMode.EQUALS,
      },
      'company.name': {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
    };
  }

  async created() {
    this.initFilter();
  }

  async onShowDialog(){
    this.cachedList = [...await whitelistUsersService.getUsers()]
  }

  isWhitelisted(user: User){
    return this.cachedList.find((cU) => user.id === cU.id)
  }


  async onRemove(user: User) {
    user.whitelisted = false
    this.cachedList = this.cachedList.filter((wUser) => wUser.id !== user.id);
    (this.$refs.table as Table).refresh()
    this.$emit("removeUser", user);
  }

  async onAdd(user: User) {
    user.whitelisted = true
    const existingUser = this.cachedList.find((cU) => user.id === cU.id)
    if(!existingUser){
      this.cachedList.push(user)
      this.$emit("addUser", user);
    }
    (this.$refs.table as Table).refresh()
  }

  async onShow(user: User){
    const routeData = this.$router.resolve({
      name: UsersRoutesEnum.USER_DETAIL, params: { userId: user.id }
    });
    window.open(routeData.href, '_blank');
  }
  
  private close() {
    this.$emit("update:visible", false);
    this.$emit('update');
  }

}