import { MessageModel } from "@/model/api/Message";
import { Options, Vue } from "vue-class-component";
import { Model, Prop } from "vue-property-decorator";

@Options({})
export default class MessageEditor extends Vue {
  @Prop({default: false})
  disabled: boolean;
  
  @Prop({default: "Message:"})
  messageLabel: string;
  
  @Model('message')
  messageModel: MessageModel;

  get subject() {
    return this.messageModel?.subject;
  }

  set subject(subject: string) {
    this.messageModel = {
      ...this.messageModel,
      subject
    };
  }

  get text() {
    return this.messageModel?.text;
  }

  set text(text: string) {
    this.messageModel = {
      ...this.messageModel,
      text
    };
  }

  get sendSms() {
    return this.messageModel?.is_sms;
  }

  set sendSms(is_sms: boolean) {
    this.messageModel = {
      ...this.messageModel,
      is_sms
    };
  }

  get sendEmail() {
    return this.messageModel?.is_email;
  }

  set sendEmail(is_email: boolean) {
    this.messageModel = {
      ...this.messageModel,
      is_email
    };
  }

  get sendPush() {
    return this.messageModel?.is_push;
  }

  set sendPush(is_push: boolean) {
    this.messageModel = {
      ...this.messageModel,
      is_push
    };
  }
}