import { Ticket } from "@/model/api/Ticket";
import { TicketStatusEnum } from "@/model/enums/TicketStatusEnum";
import { ticketsService } from "@services/tickets.service";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import {
  MomentZoneCalendar,
} from "@components";
import moment, { Moment } from "moment";

@Options({
  name: "TicketDisputeResolverDialog",
  inheritAttrs: false,
  components: {
    MomentZoneCalendar,
  }
})
export default class TicketDisputeResolverDialog extends Vue {
  @Prop() readonly ticket!: Ticket;

  get datetime_end() {
    return this.ticket.datetime_end.isValid()
      ? this.ticket.datetime_end
      : moment();
  }
  set datetime_end(m: Moment){
    this.ticket.datetime_end = m;
  }

  get today() {
    return moment();
  }

  calculatedFee: number = null; 

  get isDispute() {
    return this.ticket.status === TicketStatusEnum.DISPUTE;
  }
  
  get hasEndDate() {
    return this.ticket?.datetime_end?.isValid();
  }

  get duration() {
    const startMoment = moment(this.ticket.datetime_start);
    let d = null;

    if (!this.ticket.datetime_end.isValid()) {
      d = moment.duration(moment().diff(startMoment));
    } else {
      const endMoment = moment(this.ticket.datetime_end);
      d = moment.duration(endMoment.diff(startMoment));
    }

    const h = Math.floor(d.asHours());
    const m = Math.floor(d.minutes());

    const htext = this.$t('hours', h).trim();
    const mtext = this.$t('minutes', m).trim();

    return `${h} ${htext}` + (m ? ` ${m} ${mtext}` : '');
  }

  calcId = null; 
  debouncedCalcFee() {
    clearTimeout(this.calcId);
    this.calcId = setTimeout(this.calcFee, 300);
  }

  calcInProgress: boolean = false; 
  async calcFee() {
    try {
      this.calcInProgress = true; 

      const response = await ticketsService.calcFee(this.ticket);
      this.calculatedFee = response.fee; 
    } finally {
      this.calcInProgress = false; 
      
    }
  }

  onConfirm() {

    if ( !this.ticket.datetime_start || !this.ticket.datetime_start?.isValid() ) {
      this.$errorMessage("Start time is required");
      return
    }

    this.$waitFor( async () => {

      if ( !this.ticket.datetime_end || !this.ticket.datetime_end?.isValid()) {
        this.ticket.datetime_end = moment();
      }
      
      await ticketsService.setStatus(
        this.ticket.id,
        TicketStatusEnum.CLOSED,
        this.ticket
      );

      this.$emit('resolved');
    })
  }

  onCancel() {
    this.$emit('cancel');
  }

  onShow() {

    const validEnd = this.ticket.datetime_end?.isValid()
    const validStart = this.ticket.datetime_start?.isValid()

    if(!validEnd) {

      const today = moment();
      
      this.ticket.datetime_end = !validStart || today.isAfter(this.ticket.datetime_start)
        ? today : this.ticket.datetime_start;

    }

    if(validStart){
      this.calcFee();
    }
  }
  
}