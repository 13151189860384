import { User } from '@/model/api/User';
import { CRUD } from './base/crud';

class UsersService extends CRUD<User, any> {
    readonly endPoint = `users`;

    uploadFiles(userId: number, files: UploadFiles) {

        const {
            insuranceFile, 
            licenseFile,
            avatarImage,
            truckImage,
        } = files;

        const formData = new FormData();
        
        if (licenseFile) {
            formData.append("license_image", licenseFile);
        }

        if (insuranceFile) {
            formData.append("insurance_image", insuranceFile);
        }

        if (avatarImage) {
            formData.append("avatar_image", avatarImage);
        }

        if (truckImage) {
            formData.append("truck_image", truckImage);
        }

        const options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            // onUploadProgress: onUploadProgress
        };

        return this.post<any>(`${this.endPoint}/${userId}/upload-files`, formData, options);
    }

    downloadFile(url: string) {
        return this.get(url, { responseType: 'arraybuffer' });
    }
}

interface UploadFiles {
    insuranceFile: File,
    licenseFile: File,
    avatarImage: File,
    truckImage: File
}

export const usersService = new UsersService();



