import { BaseApiModel } from "../common/BaseApiModel";

export class MessageModel extends BaseApiModel {
    user_id     ? : number;
    shift_id    ? : number;
    contract_id ? : number;
    
    text     : string;
    subject  : string;
    
    is_sms   : boolean = false;
    is_email : boolean = false;
    is_push  : boolean = true;

    all: boolean = false;
}