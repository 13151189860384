import { Exclude, Transform } from "class-transformer";
import moment from "moment";
import { Moment } from "moment";
import { PaymentTypeEnum } from "../enums/PaymentTypeEnum";
import { Path, Zone } from "./Zone";
import timezone from "moment-timezone";
import { Contract } from "@/model/api/Contract";
import { Material } from "@/model/api/Material";
import { Company } from "./Company";

export class Shift {
	id: number;
	custom_shift_id?: number;
	code: string;
	description: string;
	job_site: string;
	contract_id: number;
	contract: Contract;
	cost_code: string;
	material_id: number;
	material: Material;

	is_expire_active: boolean;

	is_private: boolean;
	private_contract_id: number;
	private_contract: Contract;

	is_dump_same_load_site: boolean
	is_free_dump: boolean


	load_address: string
	load_city: string
	load_state: any
	load_zip: string

	dump_address: string
	dump_city: string
	dump_zip: string


	get isExpireActive(): boolean {
		return this.is_expire_active
	}

	set isExpireActive(value: boolean) {
		if (value) {
			if (this.shift_day) {
				this.expireDay ||= this.shiftDay;
			}
		} else {
			this.expire_at = null
		}
		this.is_expire_active = value
	}

	zone_load_id: number;
	zone_load: Zone;
	load_lat: number;
	load_lng: number;

	zone_dump_id: number;
	zone_dump: Zone;
	dump_lat: number;
	dump_lng: number;

	has_tickets?: boolean;

	/**
	 * Timezone name 
	 */
	zone_name: string = "America/New_York";

	shift_day: string;

	get expireDay(): Date {
		if (!this.expire_at) return null;
		return this.expire_at && moment(this.expire_at).toDate();
	}

	set expireDay(s: Date) {
		const mDay = moment(s).tz(this.zone_name, true);

		this.expire_at ||= mDay.clone().hours(23).minutes(59);
		this.expire_at
			.year(s.getFullYear())
			.month(s.getMonth())
			.date(s.getDate());
	}

	get shiftDay(): Date {
		return this.shift_day && moment(this.shift_day).toDate();
	}

	set shiftDay(s: Date) {
		const mDay = moment(s).tz(this.zone_name, true);

		this.shift_day = mDay.format("YYYY-MM-DD");

		if (this.is_expire_active) {
			this.expireDay ||= this.shiftDay;
		}

		this.start_datetime ||= mDay.clone().hours(8).minutes(0);
		this.start_datetime
			.year(s.getFullYear())
			.month(s.getMonth())
			.date(s.getDate());

		this.end_datetime ||= mDay.clone().hours(16).minutes(0);
		this.end_datetime
			.year(s.getFullYear())
			.month(s.getMonth())
			.date(s.getDate());
	}

	@Transform(
		({ value, obj }) => timezone.tz(value, obj.zone_name),
		{ toClassOnly: true }
	)
	@Transform(
		({ value, obj }) => value.tz(obj.zone_name, true)
			.clone().tz("utc")
			.format("YYYY-MM-DDTHH:mm:ss.000000\\Z"),
		{ toPlainOnly: true }
	)
	start_datetime: Moment;

	@Transform(
		({ value, obj }) => value ? timezone.tz(value, obj.zone_name) : null,
		{ toClassOnly: true }
	)
	@Transform(
		({ value, obj }) => value?.tz(obj.zone_name, true)
			.clone().tz("utc").format("YYYY-MM-DDTHH:mm:ss.000000\\Z")
		, { toPlainOnly: true }
	)
	expire_at: Moment

	get expireAt(): string {
		return this.expire_at
			? this.expire_at.format('HH:mm')
			: null;
	}

	set expireAt(v: string) {
		if (!v) {
			this.expire_at = null;
			return;
		}

		const mDay = moment(this.expireDay).tz(this.zone_name, true);

		const [h, m] = v.split(':');

		mDay.hours(+h);
		mDay.minutes(+m);
		mDay.seconds(0);

		this.expire_at = v ? mDay : null;
	}

	get startDateTime(): string {
		return this.start_datetime
			? this.start_datetime.format('HH:mm')
			: null;
	}
	set startDateTime(v: string) {
		if (!v) {
			this.start_datetime = null;
			return;
		}

		const mDay = moment(this.shiftDay).tz(this.zone_name, true);

		const [h, m] = v.split(':');

		mDay.hours(+h);
		mDay.minutes(+m);
		mDay.seconds(0);

		this.start_datetime = v ? mDay : null;
	}

	@Transform(
		({ value, obj }) => timezone.tz(value, obj.zone_name),
		{ toClassOnly: true }
	)
	@Transform(
		({ value, obj }) => value.tz(obj.zone_name, true)
			.clone().tz("utc")
			.format("YYYY-MM-DDTHH:mm:ss.000000\\Z"),
		{ toPlainOnly: true }
	)
	end_datetime: Moment;

	get endDateTime(): string {
		return this.end_datetime
			? this.end_datetime.format('HH:mm')
			: null;
	}
	set endDateTime(v: string) {
		if (!v) {
			this.end_datetime = null;
			return;
		}

		const mDay = moment(this.shiftDay).tz(this.zone_name, true);

		const [h, m] = v.split(':');

		mDay.hours(+h);
		mDay.minutes(+m);
		mDay.seconds(59);

		this.end_datetime = v ? mDay : null;
	}

	payment_type: PaymentTypeEnum = PaymentTypeEnum.CYCLE;

	cycles: number;
	fee: number;
	distance: number;

	trucks_min: number = 0;
	trucks_required: number;
	trucks_engaged: number;

	pause_minutes: number;
	note_public: string;
	note_private: string;
	state_id: number;
	contact_person: string;
	contact_phone: string;
	route: Path;
	w3load: string;
	w3dump: string;

	load_minutes: number = 3;
	load_schedule: boolean = true;

	poc_name: string;
	poc_phone: string;
	poc_email: string;

	status: ShiftStatusEnum;

	estimated_ton: number;

	customer_company_id?: number;
	customer_company?: Company;

	supplier_company_id?: number;
	supplier_company?: Company;

	is_ton_requested: boolean = false;
}

export enum ShiftStatusEnum {
	ENABLED = 1,
	DISABLED = 2
}