import { Options, Vue } from "vue-class-component";

import MessageEditor from "@/components/MessageEditor/MessageEditor.vue";
import { MessageModel } from "@/model/api/Message";
import { Prop } from "vue-property-decorator";
import { messageService } from "@services/message.service";

@Options({
  components: {
    MessageEditor
  }
})
export default class SendMessageDialog extends Vue {
  @Prop()
  userId: number;

  @Prop()
  shiftId: number;

  @Prop()
  hint: string;

  @Prop()
  text: string;

  @Prop({ default: false })
  all: boolean;

  message: MessageModel = new MessageModel();

  onShow() {
    this.text && (this.message.text = this.text);
    this.message.all = this.all;
  }

  async send() {
    this.message.user_id  = this.userId;
    this.message.shift_id = this.shiftId;

    this.$waitFor( async () => {
      await messageService.send(this.message);
      this.$successMessage("Message sent");
      this.$emit("sent");

      this.reset();
      
      this.close();
      
    });
  }

  private reset() {
    this.message = new MessageModel();
  }

  private close() {
    this.$emit('update:visible', false);
  }
}